import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Exhibitions`}</h1>
    <h3>{`2020`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://currency.designinquiry.net",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Currency`}</a>{` - 10/12 - 11/6 Texas State Galleries FL3X Space`}</strong></li>
    </ul>
    <h3>{`2019`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://futurespective.deisnginquiry.net",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Futurespective`}</a>{` - October 4 - December 14, 2019 ICA@MECA`}</strong></li>
    </ul>
    <h3>{`2018`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Biotype Newspaper,” Strutture Aperte: Libri d’Artista/Open Structures: Artist Books, curated by Devin Kovach and Shara Wasserman, Gallery of Art at Temple University Rome, Feb 2018.`}</strong></li>
      <li parentName="ul">{`Inquirers: Arzu Ozkal, Tricia Treacy, Margo Halverson, Steve Bowden, Neil Patel, Joshua Unikel, Jonathan Novak`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://filmmakermagazine.com/105954-reboot-by-the-sea/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`“Reboot by the Sea”`}</a>{` Filmmaker, September 17, 2018`}</strong></li>
      <li parentName="ul">{`Inquirer: Holly willis`}</li>
    </ul>
    <h3>{`2017`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://visualdgc.blogspot.com/2017/06/well-take-ceiling.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`“We’ll Take the Ceiling”`}</a>{` Bozeman, MT`}</strong></li>
      <li parentName="ul">{`Inquirers: Denise Gonzales Crisp, Benjamin Van Dyke`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Friendly Layers, Mixed Advice on Graphic Design,” Pomona College, September 26 – October 20, 2017.`}</strong></li>
      <li parentName="ul">{`Inquirer: Gail Swanlund`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Inside/Outside: Working our way out of the damaged now (Design as dialectics),” San Francisco State University Design Gallery, exhibition and symposium, February 2017.`}</strong></li>
      <li parentName="ul">{`Inquirers: Daniel McCafferty, Joshua Singer, Gail Swanlund`}</li>
    </ul>
    <h3>{`2015`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“NO QUO Attempts v.1.0.”  Experimental Thinking/Design Practices exhibition, Griffith University Art Gallery, Brisbane Australia, 18 Sept – 7 Nov 2015.`}</strong></li>
      <li parentName="ul">{`Inquirers: Zack Kaiser, Steve Bowden, Rachele Riley, Peter Evonuk, Molly Renda, Maia Wright, Jonathan Novak, Emily Luce, Chris Fox, Ben Van Dyke, Anita Cooney, Alice Lee, Tricia Treacy, Rebecca Tegtmeyer, Pouya Jahanshahi, Neil Patel, Margo Halverson, Joshua Unikel,  Denise Gonzales Crisp, Charles Melcher, Arzu Ozkal, Amanda Thomas, Adam Taylor, Peter Hall.`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“NO QUO Attempts v2.0.” Exchange, Crane Arts, Design Philadelphia, 8-16 Oct 2015.`}</strong></li>
      <li parentName="ul">{`Inquirers: Zack Kaiser, Steve Bowden, Rachele Riley, Peter Evonuk, Molly Renda, Maia Wright, Jonathan Novak, Emily Luce, Chris Fox, Ben Van Dyke, Anita Cooney, Alice Lee, Tricia Treacy, Rebecca Tegtmeyer, Pouya Jahanshahi, Neil Patel, Margo Halverson, Joshua Unikel,  Denise Gonzales Crisp, Charles Melcher, Arzu Ozkal, Amanda Thomas, Adam Taylor, Peter Hall.`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Get Lost Gazetteer.” Exchange, Crane Arts, Design Philadelphia, 8–16 Oct 2015.`}</strong></li>
      <li parentName="ul">{`Inquirers: Peter Hall, Maia Wright, Tricia Treacy, Arzu Ozkal, Zach Kaiser, Jonathan Novak, Joshua Unikel, Emily Luce, Klehwetua Rodney Sayers, Tristan Schultz, Anita Cooney, Margo Halverson,Gail Swanlund, Chris Fox & Steve Bowden.`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“`}<a parentName="strong" {...{
            "href": "https://www.academia.edu/5088720/Ad_Hoc_Atlas_Volume_Two_Version_One_Montreal_and_Berlin",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Ad Hoc Atlas, Vol. 2, V1: Montréal and Berlin”`}</a>{`, /mit ðə detə/: Source Materials Visualized, at The Center for Book Arts, NYC, July 2015.`}</strong></li>
      <li parentName="ul">{`Inquirer: Joshua Singer`}</li>
    </ul>
    <h3>{`2014`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DEPE Space Residency, Museum of Contemporary Art Detroit (MOCAD), October 15, 2014 - January 4, 2015`}</strong></li>
      <li parentName="ul">{`Inquirers: Steve Bowden, Bobby Campbell, Chris Fox, Ben Gaydos, Julia Yezbick, Peter Hall, Daniel McCafferty, Rachele Riley, Joshua Singer, Rebecca Tegtmeyer, Joshua Unikel, Ben Van Dyke, Gabrielle Esperdy, Lincoln Hancock`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Tangible Tweets.” `}<a parentName="strong" {...{
            "href": "http://2014.designphiladelphia.org/events/design_in_flux",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}><em parentName="a">{`Design in Flux`}</em></a>{` exhibition, Crane Arts, Philadelphia. In conjunction with Design Philadelphia Festival, Philadelphia, PA (also cited in Presentations & Papers) 9-17 October, 2014 and in the `}<a parentName="strong" {...{
            "href": "http://designonline.org.au/events/design-in-flux/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Asia Pacific Design Library`}</a>{`, Brisbane Australia, January 12-31, 2015.`}</strong></li>
      <li parentName="ul">{`Inquirers: Steve Bowden , Peter Hall, & DI Access participants`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "http://designinquiry.net/contributions/collaborating-with-ghosts-to-draw-and-write/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`“Ghost Drawing and Writing Project,”`}</a>{` with Machine Project (Los Angeles), Gamble House, Pasadena, California, October 2014.`}</strong></li>
      <li parentName="ul">{`Inquirers: Anita Cooney, Denise Gonzales Crisp, Emily Luce, Arzu Ozkal, Gail Swanlund`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Design Whiskey Inquiry” at Exhibition of Korean Society of Basic Design & Art, Pacific State University Los Angeles, CA.`}</strong></li>
      <li parentName="ul">{`Inquirer: Joshua Singer`}</li>
    </ul>
    <h3>{`2013`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.academia.edu/5088720/Ad_Hoc_Atlas_Volume_Two_Version_One_Montreal_and_Berlin",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`“Ad Hoc Atlas, Vol. 2, V1: Montréal and Berlin”`}</a>{`, Exhibition of The fifth Nordic Design Research Conference: Nordes '13: Experiments in Design Research: Expressions, Knowledge, Critique, Copenhagen, Denmark.`}</strong></li>
      <li parentName="ul">{`Inquirers: Joshua Unikel,  Denise Gonzales Crisp, Benjamin Van Dyke`}</li>
    </ul>
    <h3>{`2011`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "http://designinquiry.net/contributions/je-me-souviens/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`“Je Me Souviens”`}</a>{` at Portes Ouverte Montréal, Concordia University, Montreal Canada. Creative work from “Design Cities, Montréal” conference.`}</strong></li>
      <li parentName="ul">{`Inquirers: Bobby Campbell, Amery Calvelli, John Calvelli, Karina Cutler-Lake, Joshua Davidson, Anne Galperin, Denise Gonzales Crisp, Peter Hall, Margo Halverson, Melle Hammer, Szu-Han Ho, Alice Jarry, He Li, Emily Luce, Stuart Henley, Christopher Moore, Jennifer Nichols, Gabrielle Esperdy, Anita Cooney, Ann McDonald, Jeremy Moser, Deborah Saucier, Sareena Sernsukskul, Pattama Suksakulchai, Gail Swanlund, Florian Sametinger, Joshua Singer, David Szanto, Ben Van Dyke, Jesse Vogler, Tim Vyner.`}</li>
    </ul>
    <h3>{`Presentations & Panels`}</h3>
    <h3>{`2021`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Elliott Earls Prepostness combo with his “Studio Practice” Youtube Forthcoming in`}</strong></li>
    </ul>
    <h3>{`2019`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stefani Bardin and Assaf Tamir have been invited to present and workshop Appropriate Technologies for the New Millennium at the Centenary of the Bauhaus in Dessau Germany in March 2019 for its focus on “Knowledge” “Objects” and “Materials.”`}</strong></li>
    </ul>
    <h3>{`2018`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Do What You Can With What You Have Where You Are,” Central Saint Martins University of the Arts London, March 2018.`}</strong></li>
      <li parentName="ul">{`Inquirer: Mark Zurolo`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Situated Between,”  Rhode Island School of Design European Honors Program, Rome, January 2018`}</strong></li>
      <li parentName="ul">{`Inquirers: Arzu Ozkal, Tricia Treacy`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`"Critically Remaking the Quantified Self," workshop at CalArts, Valencia, CA, February 22, 2018.`}</strong></li>
      <li parentName="ul">{`Inquirer: Zach Kaiser`}</li>
    </ul>
    <h3>{`2017`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk and workshop at Michigan State University, April 5–6, 2017.`}</strong></li>
      <li parentName="ul">{`Inquirers: Gail Swanlund presented by Ben Van Dyke, Rebecca Tegtemeyer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk and workshop at San Diego State University Design Week, April 13–14, 2017.`}</strong></li>
      <li parentName="ul">{`Inquirers: Denise Gonzales Crisp (plus Gail Swanlund for workshop only), Neil Patel, presented by Arzu Ozkal`}</li>
    </ul>
    <h3>{`2016`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Society of Typographic Arts, STA 100 judge, Chicago, IL.`}</strong>{`
Inquirers: Denise Gonzales Crisp`}</li>
    </ul>
    <h3>{`2015`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Different Data: Experimental Design Research for Mapping Cities” in `}<em parentName="strong">{`The Virtuous Circle: Design Culture and Experimentation`}</em>{`, the Cumulus 2015 Conference, Politecnico di Milano, Milan, Italy.`}</strong></li>
      <li parentName="ul">{`Inquirers: Rachele Riley, Joshua Singer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Presentation and Workshop, ‘Sensibility and the Shifting Experience of Knowing Place’  at the `}<em parentName="strong">{`Mapping Ephemeralities / Ephemeral Cartographies`}</em>{`, The International Cartographic Association commissions on Art & Cartography and on Maps and Society, Rio de Janeiro, Brazil, August 21–22, 2015.`}</strong></li>
      <li parentName="ul">{`Inquirer: Rachele Riley`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Presentation and Workshop, “Ad Hoc Atlas” and “Typographic Typologies” at the `}<em parentName="strong">{`Mapping Ephemeralities / Ephemeral Cartographies`}</em>{`, The International Cartographic Association commissions on Art & Cartography and on Maps and Society, Rio de Janeiro, Brazil, August 21–22, 2015`}</strong></li>
      <li parentName="ul">{`Inquirers: Joshua Singer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“On Visual Essayistics” at NonFiction Now, Flagstaff , AZ, October 28–31, 2015`}</strong></li>
      <li parentName="ul">{`Inquirers: Joshua Unikel, Denise Gonzales Crisp, Benjamin Van Dyke, Gail Swanlund`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk at Vermont College of Fine Arts, Montpellier, VT, October 14–15, 2015`}</strong></li>
      <li parentName="ul">{`Inquirers: Gail Swanlund`}</li>
    </ul>
    <h3>{`2014`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk at California Institute of the Arts, Valencia, CA.`}</strong></li>
      <li parentName="ul">{`Inquirer: Denise Gonzales Crisp`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk and workshop at California Institute of the Arts, Valencia, CA.`}</strong></li>
      <li parentName="ul">{`Inquirer: Emily Luce `}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk at Otis College of Art`}</strong></li>
      <li parentName="ul">{`Inquirers: Emily Luce`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Presentation at conference Regarding the City, ‘Design Cities: Detroit’ Wayne State University, Detroit MI, October 31, 2014.`}</strong></li>
      <li parentName="ul">{`Inquirers: Dan McCafferty, Rachele Riley, Joshua Singer, Ben Van Dyke`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Presentation at Michigan State University to Graphic Design Dept, ‘Design Cities: Detroit’ October 26, 2014.`}</strong></li>
      <li parentName="ul">{`Inquirers: Rachele Riley, Joshua Singer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Panel Philadelphia`}</strong></li>
      <li parentName="ul">{`Inquirers: Inquirers: Peter Hall, Denise Gonzales Crisp`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Productive Counter-Production: Notes on Creative Practices…” California Institute of the Arts, Valencia, CA.`}</strong></li>
      <li parentName="ul">{`Inquirers: Gabrielle Esperdy`}</li>
    </ul>
    <h3>{`2013`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“DesignInquiry” Design Speakers Series, February 20, 2013, Virginia Commonwealth University Graphic Design Division.`}</strong></li>
      <li parentName="ul">{`Inquirers: Margo Halverson and Emily Luce`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The Centre for Retrofitting and Failure Techniques (C.R.A.F.T.), October 8, 2013, University of Ontario Institute of Technology Department of Computer Science, Oshawa, Ontario, Canada.`}</strong></li>
      <li parentName="ul">{`Inquirer: Emily Luce`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Artifice, the Semiosphere, and Counter-Consciousness -or- A Model for a Counter Graphic Design” presented at `}<a parentName="strong" {...{
            "href": "http://nordes.org/nordes2013/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`The fifth Nordic Design Research Conference: Nordes '13: Experiments in Design Research: Expressions, Knowledge, Critique`}</a>{`, Copenhagen, Denmark/Malmö, Sweden.`}</strong></li>
      <li parentName="ul">{`Inquirer: Joshua Singer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Communication Counter-Design: Alternative Design and Research Methods” presented at the `}<a parentName="strong" {...{
            "href": "http://www.cumulusassociation.org/component/content/1486-drscumulus-oslo-2013-2nd-international-conference-for-design-education-researchers/152",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`DRS (Design Research Society)/Cumulus 2013 Conference`}</a>{`, Oslo, Norway.`}</strong></li>
      <li parentName="ul">{`Inquirer: Joshua Singer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.academia.edu/1793904/Towards_an_Open_Source_Model_for_Design_Research",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`“Towards an Open Source Model for Design Research”`}</a>{`, CAA (College Art Association) Annual Conference, New York, NY.`}</strong></li>
      <li parentName="ul">{`Inquirers: Christopher Moore, Joshua Singer`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“The Designer as … Author, Producer, Activist, Entrepeneur, Curator, and Collaborator: New Models for Communicating.” BIS Publishers. ISBN 9063692927`}</strong></li>
      <li parentName="ul">{`Inquirer: Steven McCarthy`}</li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Talk and workshop at San Diego State University, April 17, 2013.`}</strong></li>
      <li parentName="ul">{`Inquirers: Gail Swanlund presented by Arzu Ozkal`}</li>
    </ul>
    <h3>{`2012`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Counter-Design and Counter-Consciousness: Alternative Design Methods” at the UAAC-AAUC (Universities Art Association of Canada) Annual Conference, Montréal Canada.`}</strong></li>
      <li parentName="ul">{`Inquirer: Joshua Singer`}</li>
    </ul>
    <h3>{`2011`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“Meeting Halfway, Triangle Offense, Conducting, and Dance Notation” at AIGA/Osher Auditorium, Maine College of Art`}</strong></li>
      <li parentName="ul">{`Inquirers: Margo Halverson`}</li>
    </ul>
    <h3>{`2006`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`“DesignInquiry: From Mainland to Island”; The Design Frontier, AIGA Education conference; Denver, Colorado.`}</strong></li>
      <li parentName="ul">{`Inquirers: Peter Hall`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      